import React from 'react';

import './AnchorHeader.scss';

export default function AnchorHeader({ title }: IAnchorHeader) {
  return <h2 className="anchor-banner">{title}</h2>;
}

interface IAnchorHeader {
  title: string;
}

export interface IAnchorRefs {
  anchors: { [index: string]: React.RefObject<any> };
}

import React, { createRef, useEffect } from 'react';
import { throttle } from 'throttle-debounce';

import './Home.scss';

import Blog from './blog/Blog';
import HomePhotoBanner from './HomePhotoBanner';
import Resume from './aboutme/AboutMe';
import Skills from './skills/Skills';

export default function Home() {
  document.documentElement.dataset.scroll = window.scrollY.toString(); // TODO: Remove when all main pages implemented.
  document.documentElement.dataset.scrollFreeze = "false";

  const anchors = {
    skills: createRef(),
    resume: createRef(),
    blog: createRef()
  };

  const example = throttle(60, () => {
    // ...
  });

  useEffect(() => {
    example();

    return function cleanup() {
      // ...
    };
  });

  return (
    <div className="content">
      <HomePhotoBanner anchors={anchors} />
      <Skills anchors={anchors} />
      <Resume anchors={anchors} />
      <Blog anchors={anchors} />
    </div>
  );
}

import React from 'react';
import 'lazysizes';
import ArtData from './ArtData'; // TODO: Replace with firestore data.
import { Grid, Container } from '@material-ui/core';

export default function ArtStation() {
  const gallery = ArtData.map((elem, index) => {
    console.log(elem);
    return (
      <Grid key={`artstation-${index}`} item xs>
        <img
          alt={elem.title}
          data-src={elem.url}
          width={200}
          className="lazyload"
        />
      </Grid>
    );
  });

  return (
    <Container>
      <Grid container justify="center" alignItems="center" spacing={2}>
        {gallery}
      </Grid>
    </Container>
  );
}

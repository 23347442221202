import React from 'react';

import { Container, Paper } from '@material-ui/core';
import AnchorHeader, { IAnchorRefs } from '../../../contents/AnchorHeader';

import './Skills.scss';

const LOGO_DIR = '/assets/imgs/logos';

export default function Skills({ anchors }: IAnchorRefs) {
  let largestSkillGroup = 0;

  const skills: ISkillType[] = [
    {
      name: 'Artwork',
      skills: [
        {
          name: 'Blender3D',
          logo: `${LOGO_DIR}/blender3d.svg`
        },
        {
          name: 'Krita',
          logo: `${LOGO_DIR}/krita.png`
        },
        {
          name: 'Substance',
          logo: `${LOGO_DIR}/substance.svg`
        }
      ]
    },
    {
      name: 'JavaScript',
      skills: [
        {
          name: 'React',
          logo: `${LOGO_DIR}/react.svg`
        },
        {
          name: 'JavaScript',
          logo: `${LOGO_DIR}/javascript.svg`
        },
        {
          name: 'TypeScript',
          logo: `${LOGO_DIR}/typescript.svg`
        },
        {
          name: 'jQuery',
          logo: `${LOGO_DIR}/jquery.svg`
        },
        {
          name: 'NodeJS',
          logo: `${LOGO_DIR}/nodejs.svg`
        }
      ]
    },
    {
      name: 'Frontend',
      skills: [
        {
          name: 'JSX',
          logo: `${LOGO_DIR}/react.svg`
        },
        {
          name: 'HTML5',
          logo: `${LOGO_DIR}/html5.svg`
        },
        {
          name: 'CSS3',
          logo: `${LOGO_DIR}/css3.svg`
        },
        {
          name: 'SASS/SCSS',
          logo: `${LOGO_DIR}/sass.svg`
        },
        {
          name: 'Bootstrap',
          logo: `${LOGO_DIR}/bootstrap.svg`
        },
        {
          name: 'Material UI',
          logo: `${LOGO_DIR}/material-ui.svg`
        }
      ]
    },
    {
      name: 'Backend',
      skills: [
        {
          name: 'C#',
          logo: `${LOGO_DIR}/c-sharp.svg`
        },
        {
          name: 'C++',
          logo: `${LOGO_DIR}/cpp.svg`
        },
        {
          name: 'C',
          logo: `${LOGO_DIR}/c.svg`
        },
        {
          name: 'Java',
          logo: `${LOGO_DIR}/java.svg`
        },
        {
          name: 'Python',
          logo: `${LOGO_DIR}/python.svg`
        },
        {
          name: 'Go',
          logo: `${LOGO_DIR}/golang.svg`
        },
        {
          name: 'PHP',
          logo: `${LOGO_DIR}/php.svg`
        },
        {
          name: 'COBOL',
          logo: `${LOGO_DIR}/cobol.png`
        }
      ]
    },
    {
      name: 'Databases',
      skills: [
        {
          name: 'Firestore',
          logo: `${LOGO_DIR}/firestore.svg`
        },
        {
          name: 'Firebase RDB',
          logo: `${LOGO_DIR}/firebase-rdb.svg`
        },
        {
          name: 'MySQL',
          logo: `${LOGO_DIR}/mysql.svg`
        },
        {
          name: 'MongoDB',
          logo: `${LOGO_DIR}/mongodb.svg`
        }
      ]
    },
    {
      name: 'Build Tools',
      skills: [
        {
          name: 'Webpack',
          logo: `${LOGO_DIR}/webpack.svg`
        },
        {
          name: 'Babel',
          logo: `${LOGO_DIR}/babel.svg`
        },
        {
          name: 'Jest',
          logo: `${LOGO_DIR}/jest.svg`
        },
        {
          name: 'NUnit',
          logo: `${LOGO_DIR}/nunit.svg`
        }
      ]
    },
    {
      name: 'Other',
      skills: [
        {
          name: 'Firebase',
          logo: `${LOGO_DIR}/firebase.svg`
        },
        {
          name: 'Wordpress',
          logo: `${LOGO_DIR}/wordpress.svg`
        },
        {
          name: 'Unity',
          logo: `${LOGO_DIR}/unity.svg`
        },
        {
          name: 'Github',
          logo: `${LOGO_DIR}/github.svg`
        }
      ]
    }
  ];

  skills.forEach((section) => {
    if (section.skills.length > largestSkillGroup)
      largestSkillGroup = section.skills.length;
  });

  interface ISkillType {
    name: string;
    skills: ISkill[];
  }

  interface ISkill {
    name: string;
    logo: string;
  }

  function loadSkills() {
    const components: JSX.Element[] = [];

    skills.forEach((section) => {
      const subComponents: JSX.Element[] = [];

      section.skills.forEach((skill) => {
        subComponents.push(
          <div className="skill" key={skill.name}>
            <img src={skill.logo} className="skill-logo" alt={skill.name} />
            <div className="footer">{skill.name}</div>
          </div>
        );
      });

      components.push(
        <Paper elevation={3} className="skills section" key={section.name}>
          <h3 className="header">{section.name}</h3>
          <div className={`grouping skillset-count-${largestSkillGroup}`}>
            {subComponents}
          </div>
        </Paper>
      );
    });

    return components;
  }

  return (
    <div className="section" ref={anchors.skills}>
      <AnchorHeader title="Skills" />
      <Container maxWidth="lg">{loadSkills()}</Container>
    </div>
  );
}

import React from 'react';

import IPostData from './IPostData';

import { Grid, Paper } from '@material-ui/core';
import DateFormat from './DateFormat';
import './Post.scss';

export default function Post(post: IPost) {
  return (
    <Grid item xs={12} className="post">
      <Paper elevation={3} className="post-content">
        <h3 className="header">
          <div className="title">{post.title}</div>
          <div className="author">by {`${
            post.author.firstName
          } ${post.author.lastName.charAt(0)}. <${post.author.username}>`}</div>
          <DateFormat timestamp={post.timestamp} />
        </h3>

        <div className="summary">{post.summary}</div>
      </Paper>
    </Grid>
  );
}

export interface IPost extends IPostData {
  summary: string;
}

import React from 'react';

import './AboutMe.scss';

import { Container, Paper } from '@material-ui/core';
import AnchorHeader, { IAnchorRefs } from '../../../contents/AnchorHeader';

/* FontAwesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faYoutube,
  faTwitch,
  faArtstation,
  faDiscord,
  faInstagram
} from '@fortawesome/free-brands-svg-icons';

export default function Info({ anchors }: IAnchorRefs) {
  return (
    <div className="section" ref={anchors.aboutme}>
      <AnchorHeader title="About Me" />
      <Container maxWidth="sm">
        <Paper elevation={3}>
          {'Work in progress...'}
          <div className="aboutme-content">
            <div className="flex-splitter">
              <div className="aboutme-header">
                <div className="first-name">Kristopher</div>
                <div className="last-name">Ali</div>
              </div>
            </div>
            <div className="flex-splitter">
              <div className="aboutme-info">
                <div className="flex-splitter">
                  <div className="aboutme-languages aboutme-section">
                    <div className="aboutme-bold">Language</div>
                    <div className="aboutme-text">English</div>
                    <div className="aboutme-text">Basic French</div>
                  </div>
                  <div className="aboutme-email aboutme-section">
                    <div className="aboutme-bold">Email</div>
                    <div className="aboutme-text">
                      <a href="mailto:contact@makosai.com">
                        contact@makosai.com
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex-splitter">
                  <div className="aboutme-connections aboutme-section">
                    <div className="aboutme-bold">Connections</div>
                    <div className="aboutme-text">
                      <a href="https://makosai.artstation.com/">
                        <FontAwesomeIcon icon={faArtstation} />
                      </a>
                      <a href="https://twitter.com/makosai_/">
                        <FontAwesomeIcon icon={faTwitter} />
                      </a>
                      <a href="https://instagram.com/makosai_/">
                        <FontAwesomeIcon icon={faInstagram} id="#ig-mask" />
                      </a>
                      <a href="https://www.youtube.com/channel/UCCy74US6RU5B_adI7D-d5bw">
                        <FontAwesomeIcon icon={faYoutube} />
                      </a>
                      <a href="https://twitch.tv/makosai">
                        <FontAwesomeIcon icon={faTwitch} />
                      </a>
                      <a href="https://discord.makosai.com">
                        <FontAwesomeIcon icon={faDiscord} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </Container>
    </div>
  );
}

// Modules
import React from 'react';
import ReactDOM from 'react-dom';

// Resources
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import './index.scss';

// Components
import AppContexts from './components/contexts/AppContexts';
import App from './App';


import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <AppContexts>
    <App />
  </AppContexts>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { Breadcrumbs, Container } from '@material-ui/core';
import { BubbleChart, Chat, Description } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { IAnchorRefs } from '../../contents/AnchorHeader';

export default function HomePhotoBanner({ anchors }: IAnchorRefs) {
  const datasets = {
    default:
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&fit=crop&w=1000&h=1000&q=80',
    '5600px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=5799&h=594&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=5799&h=594&q=80 2x',
    '5400px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=5599&h=594&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=5599&h=594&q=80 2x',
    '5200px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=5399&h=594&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=5399&h=594&q=80 2x',
    '5000px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=5199&h=594&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=5199&h=594&q=80 2x',
    '4800px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=4999&h=594&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=4999&h=594&q=80 2x',
    '4600px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=4799&h=594&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=4799&h=594&q=80 2x',
    '4400px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=4599&h=594&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=4599&h=594&q=80 2x',
    '4200px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=4399&h=594&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=4399&h=594&q=80 2x',
    '4000px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=4199&h=594&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=4199&h=594&q=80 2x',
    '3800px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=3999&h=594&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=3999&h=594&q=80 2x',
    '3600px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=3799&h=594&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=3799&h=594&q=80 2x',
    '3400px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=3599&h=594&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=3599&h=594&q=80 2x',
    '3200px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=3399&h=594&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=3399&h=594&q=80 2x',
    '3000px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=3199&h=594&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=3199&h=594&q=80 2x',
    '2800px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=2999&h=594&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=2999&h=594&q=80 2x',
    '2600px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=2799&h=594&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=2799&h=594&q=80 2x',
    '2400px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=2599&h=594&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=2599&h=594&q=80 2x',
    '2200px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=2399&h=594&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=2399&h=594&q=80 2x',
    '2000px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=2199&h=594&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=2199&h=594&q=80 2x',
    '1800px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=1999&h=594&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=1999&h=594&q=80 2x',
    '1600px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=1799&h=594&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=1799&h=594&q=80 2x',
    '1400px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=1599&h=594&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=1599&h=594&q=80 2x',
    '1200px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=1399&h=594&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=1399&h=594&q=80 2x',
    '1000px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=1199&h=594&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=1199&h=594&q=80 2x',
    '800px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=999&h=594&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=999&h=594&q=80 2x',
    '600px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=799&h=485&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=799&h=485&q=80 2x',
    '400px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=599&h=470&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=599&h=470&q=80 2x',
    '200px':
      'https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=1&auto=format&fit=crop&w=399&h=440&q=80 1x, https://images.unsplash.com/photo-1584223783782-4bde1b300193?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&dpr=2&auto=format&fit=crop&w=399&h=440&q=80 2x'
  };

  function handleBreadcrumb(e: any) {
    if (
      e.target === undefined ||
      e.target === null ||
      e.target.href === undefined ||
      e.target.href === null
    ) {
      return;
    }

    const href = e.target.href.split('#')[1];

    if (!anchors.hasOwnProperty(href) || anchors[href].current === null) {
      return;
    }

    const element = anchors[href].current;
    const offset = 62;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }

  return (
    <div className="photo-banner">
      <div className="picture-set">
        <div className="picture-frame">
          <picture className="_2XHNB">
            <source srcSet={datasets['5600px']} media="(min-width: 5600px)" />
            <source srcSet={datasets['5400px']} media="(min-width: 5400px)" />
            <source srcSet={datasets['5200px']} media="(min-width: 5200px)" />
            <source srcSet={datasets['5000px']} media="(min-width: 5000px)" />
            <source srcSet={datasets['4800px']} media="(min-width: 4800px)" />
            <source srcSet={datasets['4600px']} media="(min-width: 4600px)" />
            <source srcSet={datasets['4400px']} media="(min-width: 4400px)" />
            <source srcSet={datasets['4200px']} media="(min-width: 4200px)" />
            <source srcSet={datasets['4000px']} media="(min-width: 4000px)" />
            <source srcSet={datasets['3800px']} media="(min-width: 3800px)" />
            <source srcSet={datasets['3600px']} media="(min-width: 3600px)" />
            <source srcSet={datasets['3400px']} media="(min-width: 3400px)" />
            <source srcSet={datasets['3200px']} media="(min-width: 3200px)" />
            <source srcSet={datasets['3000px']} media="(min-width: 3000px)" />
            <source srcSet={datasets['2800px']} media="(min-width: 2800px)" />
            <source srcSet={datasets['2600px']} media="(min-width: 2600px)" />
            <source srcSet={datasets['2400px']} media="(min-width: 2400px)" />
            <source srcSet={datasets['2200px']} media="(min-width: 2200px)" />
            <source srcSet={datasets['2000px']} media="(min-width: 2000px)" />
            <source srcSet={datasets['1800px']} media="(min-width: 1800px)" />
            <source srcSet={datasets['1600px']} media="(min-width: 1600px)" />
            <source srcSet={datasets['1400px']} media="(min-width: 1400px)" />
            <source srcSet={datasets['1200px']} media="(min-width: 1200px)" />
            <source srcSet={datasets['1000px']} media="(min-width: 1000px)" />
            <source srcSet={datasets['800px']} media="(min-width: 800px)" />
            <source srcSet={datasets['600px']} media="(min-width: 600px)" />
            <source srcSet={datasets['400px']} media="(min-width: 400px)" />
            <source srcSet={datasets['200px']} media="(min-width: 200px)" />
            <source srcSet={datasets['1800px']} />
            <img
              className="_1OiuV"
              role="presentation"
              alt="Lucas Ludwig's photography piece."
              src={datasets['1800px']}
            />
            <div className="credits">
              Photo by{' '}
              <a href="https://unsplash.com/@luddyphoto">Lucas Ludwig</a>
            </div>
          </picture>
        </div>
        <Container maxWidth="md" className="picture-captions">
          <h1 className="title">Makosai</h1>
          <h2 className="subtitle">Programmer / Artist</h2>
          <p className="subtext">Ambition is scarce.</p>
          <p className="subtext">But not here.</p>
          <Breadcrumbs aria-label="breadcrumb" className="section-links">
            <Link className="crumb crumb-skills" to="#skills" onClick={handleBreadcrumb}>
              <BubbleChart />
              Skills
            </Link>
            <Link className="crumb crumb-resume" to="#resume" onClick={handleBreadcrumb}>
              <Description />
              Resume
            </Link>
            <Link className="crumb crumb-blog" to="#blog" onClick={handleBreadcrumb}>
              <Chat />
              Blog
            </Link>
          </Breadcrumbs>
        </Container>
      </div>
    </div>
  );
}

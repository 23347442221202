export default [
  {
    title: 'Eyeball',
    description: `I decided to open an old project of mine and improve it with some of the things I've learned (lighting, shaders, and better ways to smooth).`,
    caption: 'Aye.',
    url:
      'https://cdna.artstation.com/p/assets/images/images/023/608/284/large/kristopher-ali-eye1080.jpg?1579753928'
  },
  {
    title: 'Moon & Nebula',
    description: `Day 6 - Moon & Nebula<br />
<br />
  I started getting into procedural shaders and volumetrics. It was fun playing with methods of getting the clouds to look decent. This is something I'll revisit to make it even better.`,
    caption: 'A rocky moon between some radioactive cotton candy space clouds!',
    url:
      'https://cdnb.artstation.com/p/assets/images/images/023/608/165/large/kristopher-ali-moon-and-nebula-2.jpg?1579753859'
  }
];

import { IRoute } from './Routes';

import Art from '../pages/Art/Art';
import Code from '../pages/Code/Code';
import Home from '../pages/Home/Home';

// tslint:disable: object-literal-sort-keys

const RootRoutes: IRoute[] = [
  {
    name: 'Home',
    description: `Makosai's programming and art portfolio. A collection of artwork, software, and games. Also, social media links to Twitch, ArtStation, Instagram, or Twitter.`,
    path: '/',
    component: Home
  },
  {
    name: 'Art',
    description: `Here's a collection of both 3D and 2D art by Makosai. Links to Instagram and ArtStation are also provided.`,
    path: '/art',
    component: Art
  },
  {
    name: 'Code',
    description: `Open-source and private projects by Makosai can be found here. This includes the open-source oRPG game, Reia, and the open-source Unity 3D netcode, Sustenet.`,
    path: '/code',
    component: Code
  }
];

export default RootRoutes;

import { Spa } from '@material-ui/icons';
import React from 'react';

export default function NavBrand() {
  return (
    <div className="nav-brand">
      <Spa className="icon" />
      <div className="title">
        {'Makosai'}
        <div className="subtitle">Programmer / Artist</div>
      </div>
    </div>
  );
}

import React, { createRef } from 'react';

import './Art.scss';

import ArtCarousel from './ArtCarousel';
import ArtStation from './ArtStation';

export default function Art() {
  document.documentElement.dataset.scroll = window.scrollY.toString(); // TODO: Remove when all main pages implemented.
  document.documentElement.dataset.scrollFreeze = "false";

  const anchors = {
    blender3d: createRef<any>()
  };

  return (
    <div className="content">
      <ArtCarousel anchors={anchors} />
      <div ref={anchors.blender3d}>Art section WIP...</div>
      <ArtStation />
    </div>
  );
}

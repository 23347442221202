import React from 'react';

import { Container, Grid } from '@material-ui/core';
import AnchorHeader, { IAnchorRefs } from '../../../contents/AnchorHeader';
import Post, { IPost } from './Post';

const postExample: IPost = {
  timestamp: new Date().getTime().toString(),
  author: {
    firstName: 'Kristopher',
    lastName: 'Ali',
    username: 'Makosai'
  },
  title: 'Example Post',
  categories: ['example'],
  thumbnail: './storage/example.png',
  summary:
    'This is an example post. The website is still currently under construction.'
};

export default function Blog({ anchors }: IAnchorRefs) {
  return (
    <div className="section" ref={anchors.blog}>
      <AnchorHeader title="Blog" />
      <Container maxWidth="lg">
        <div className="posts">
          <Grid container spacing={3}>
            <Post {...postExample} />
            <Post {...postExample} />
            <Post {...postExample} />
          </Grid>
        </div>
      </Container>
    </div>
  );
}

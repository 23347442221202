import React from 'react';

export default function DateFormat({ timestamp }: IDate) {
  const options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  };

  return (
    <div className="date">
      {new Date(parseInt(timestamp)).toLocaleDateString(undefined, options)}
    </div>
  );
}

interface IDate {
  timestamp: string;
}
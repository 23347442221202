import React from 'react';

import './Code.scss';

export default function Code() {
  document.documentElement.dataset.scroll = "1";  // TODO: Remove when all main pages implemented.
  document.documentElement.dataset.scrollFreeze = "true";

  return <div className="content nav-boxed">Code section WIP...</div>;
}

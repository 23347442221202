import React from 'react';

import './App.scss';

import { KeyboardArrowUp } from '@material-ui/icons';
import AppRouter from './components/routes/AppRouter';

function App() {
  document.documentElement.dataset.scroll = "0";

  document.addEventListener(
    'scroll',
    () => {
      if(document.documentElement.dataset.scrollFreeze !== "true") {
        document.documentElement.dataset.scroll = window.scrollY.toString();
      }
    },
    { passive: true }
  );

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  return (
    <div>
      <AppRouter />
      <KeyboardArrowUp id="gototop" onClick={scrollToTop} />
    </div>
  );
}

export default App;
